'use strict';

angular.module('enervexSalesappApp').controller('SpecificationCtrl', function($scope, $stateParams, $state, $q, SpecificationsService, Design, SpecificationTemplate, User, Account, Job, Product, FileUploader, _, Auth, Application, Fan, Control, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.specificationId = $stateParams.specificationId;
	$scope.isInternalMember = Auth.isInternalMember;

	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	$scope.job = Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	});
	if ($stateParams.mode == "edit") {
		$scope.mode = "edit"
	}
	if ($scope.mode === 'edit'){
		$scope.editForm = true;
	}
	Control.query().$promise.then(function(controls){
		console.log('got controls', controls);
		$scope.controls = controls
	})
	Fan.query({
		limit:1000
	}).$promise.then(function(fans){
		console.log('got fans', fans);
		$scope.fans = fans
	})
	$scope.editState = function(){
		$stateParams.mode = 'edit';
		$state.go('specification', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			specificationid: $scope.specificationid,
			mode: 'edit'
		}, {
			inherit: false,
			reload: true
		});
	}
	$scope.previousVersion = function(version){
		var latestV = _.max($scope.specificationVersions, function(spec){
			return spec.version;
		});

		if (version != latestV) {
			// $stateParams.version = version.version;
			// $scope.mode = 'version';
			$state.go('specification', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				specificationid: $scope.specificationid,
				version: version.version,
				mode: 'version'
			}, {
				inherit: false,
				reload: true
			});
			// $scope.specification = version;
		} else {
			// $scope.mode = '';
			$state.go('specification', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				specificationid: $scope.specificationid,
			}, {
				inherit: false,
				reload: true
			});
			// $scope.mode = '';
			// $scope.specification = version;
		}
	}
	if ($stateParams.specificationId != "new"){
		fetchFans();
		fetchComments();
		fetchSpecification();
		getSpecificationVersions();
		getCompiledVersion();
		getDesigns();
	} else {
		getDesigns();
		getTemplates();
		fetchFans();
		$scope.editForm = true;
		$scope.specification = {options: {}};
	}
	function fetchSpecification(){
		SpecificationsService.getSpecification().$promise.then(function(spec){
			$scope.specification = spec;
			$scope.checkedMembers = $scope.specification.explicitMembers;

			if ($scope.specification.options.application) {
				setFanTypes();
			}
			if ($stateParams.version != undefined) {
				var version = Number($stateParams.version);
				SpecificationsService.fetchSpecificationVersions().$promise.then(function(res){
					$scope.specificationVersions = res;
					// _.each($scope.specificationVersions, function(spec){
					// 	if (spec.version == version) {
					// 		$scope.previousVersion(design);
					// 	}
					// })
				})
			}
			if ($scope.specification.design != null) {
				Design.versions({
					accountId: $stateParams.accountId,
					jobId: $stateParams.jobId,
					id: $scope.specification.design.design._id
				}).$promise.then(function(versions) {
					$scope.specification.design.versionList = _.max(versions, function(ver) { return ver.version; }).version;
				});
			}
		});
	}
	function getSpecificationVersions(){
		SpecificationsService.fetchSpecificationVersions().$promise.then(function(res){
			$scope.specificationVersions = res;
		})
	}
	function getCompiledVersion(){
		SpecificationsService.compile({
			jobId: $stateParams.jobId,
			accountId: $stateParams.accountId,
			id: $stateParams.specificationId
		}).$promise.then(function(res) {
			$scope.compiled = res.message;
		})
	}
	function getDesigns(){
		Design.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}).$promise.then(function(res){
			$scope.designs = res;
			_.each($scope.designs, function(design){
				design.versionList = design.version;
			})
		});
	}
	function fetchFans() {
		return Design.fans({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}).$promise.then(function(res) {
			$scope.fans = res;
			setFanTypes()
		});
	}
	function getTemplates() {
		SpecificationTemplate.query({}).$promise.then(function(res) {
			$scope.templates = res;
		});
	}
	$scope.clearForm = function(){
		$scope.fanTypes = [];
	}
	$scope.applications = Application.query();
	$scope.fanTypes = [];
	$scope.voltages = {
		options: []
	}
	$scope.controls = {
		options: []
	}
	$scope.checkedMembers = [];
	$scope.toggleCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedMembers.indexOf(memberId) === -1){
			$scope.checkedMembers.push(member.user._id);
		} else {
			$scope.checkedMembers.splice($scope.checkedMembers.indexOf(memberId), 1);
		}
	}
	$scope.checkedEmailMembers = [];
	$scope.toggleEmailCheck = function(member){
		$scope.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.setChecks = false;
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
		console.log($scope.checkedEmailMembers)
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}
	$scope.submitSpecification = function() {
		// for now only 1 template so select it!
		$scope.specification.template = $scope.templates[0]._id;

		$scope.specification.explicitMembers = $scope.checkedMembers;

		if ($scope.specification.notifyMembers === 'all'){
			$scope.specification.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if ($scope.specification.notifyMembers === 'some') {
			$scope.specification.notifyMembers = $scope.checkedEmailMembers;
		} else if (spec.notifyMembers === 'none'){
			$scope.specification.notifyMembers = [];
		}

		SpecificationsService.newSpecification($scope.specification).$promise.then(function(res) {
			console.log('specification', $scope.specification)
			$state.go('specification', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				specificationid: res._id
			}, {
				inherit: false,
				reload: true
			});
		});
	}
	$scope.deleteSpecification = function(){
		SpecificationsService.deleteSpecification($scope.specification).$promise.then(function(res){
			$state.go('specifications', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				inherit: false,
				reload: true
			});
		})
	}
	// comments
	function fetchComments(){
		SpecificationsService.getComments().$promise.then(function(specificationComments){
			$scope.specificationComments = specificationComments;
		});
	}
	$scope.newComment = {};
	var uploader = $scope.uploader = new FileUploader({});
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembers === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembers === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembers === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue,function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.design.visibility);
				promises.push(SpecificationsService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				SpecificationsService.createComment(newComment).$promise.then(function(res) {
					fetchComments();
					$scope.newComment = {};
					$scope.uploader.queue = [];
				});
			})
		} else {
			SpecificationsService.createComment(newComment).$promise.then(function(res) {
				fetchComments();
				$scope.uploader.queue = [];
				$scope.newComment = {};
			});
		}
	}
	$scope.deleteComment = function(comment) {
		SpecificationsService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		SpecificationsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;

		SpecificationsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.selectDesign = function(design) {
		$scope.formChanged = true;

		if (design) {
			$scope.specification.design = { design: design._id, version: design.version };
		} else {
			$scope.specification.design = {};
		}
	}
	$scope.updateDesignVersion = function(design) {
		$scope.formChanged = true;

		if (design) {
			$scope.specification.design = { design: design._id, version: design.version };
		}
	}
	$scope.cancelSpecificationEdit = function(){
		fetchSpecification();
		$scope.mode = '';
		$state.go('specification', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			specificationid: $scope.specificationid
		}, {
			reload: true,
			inherit: false
		});

		$scope.formChanged = false;
	}
	$scope.saveSpecificationChanges = function(){
		if ($scope.specification.visibility === 'explicit'){
			$scope.specification.explicitMembers = $scope.checkedMembers;
		} else {
			$scope.specification.explicitMembers = [];
		}
		if ($scope.specification.design) {
			$scope.specification.design = {
				design: $scope.specification.design.design.id,
				version: $scope.specification.design.version
			};
		}
		SpecificationsService.updateSpecification($scope.specification).$promise.then(function(res){
			fetchSpecification();
			getSpecificationVersions();
		});
		$scope.mode = '';
		$state.go('specification', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			specificationid: $scope.specificationid,
		}, {
			inherit: false,
			reload: true
		});
		$scope.formChanged = false;
	}
	$scope.onApplicationChange = function() {
		var application = _.find($scope.applications, function(a){
			return a._id == $scope.specification.options.application._id
		})
		$scope.specification.options.application = application
		$scope.formChanged = true;
		setFanTypes();
	}
	function setFanTypes() {
		var filtered = _.filter($scope.fans, function(fan){
			var application = $scope.specification && $scope.specification.options.application;
			return application && (fan[application.computeAs] == true);
		});
		var names = _.map(filtered, 'name');

		$scope.fanTypes = names;
	}
	$scope.onFanTypeChange = function() {
		$scope.formChanged = true;
		setVoltage();
	}
});
